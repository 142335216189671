import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/developers/overrides/

const useStore = createStore({
    background: "#0099FF",
})

export function withRotate(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 90 }}
                transition={{ duration: 2 }}
            />
        )
    }
}

export function withHover(Component): ComponentType {
    return (props) => {
        return <Component {...props} whileHover={{ scale: 1.05 }} />
    }
}

export function withRandomColor(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                animate={{
                    background: store.background,
                }}
                onClick={() => {
                    setStore({ background: randomColor() })
                }}
            />
        )
    }
}
import { useState } from "react"
import { motion } from "framer-motion"
import { Button } from "@/components/ui/button"

export default function PricingSlider() {
    const [index, setIndex] = useState(0)

    const pricingSlides = [
        [
            {
                title: "Basic",
                price: "$29.99",
                description: "Get Your Music Ready for All Platforms",
                features: [
                    "Full Commercial Use License",
                    "High-Quality WAV File",
                    "Distribute on Major Platforms",
                    "Stream Limit: 100,000 Total Streams",
                ],
            },
            {
                title: "Trackouts",
                price: "$74.99",
                description: "Get Your Song on All Streaming Platforms",
                features: [
                    "Full Commercial Use License",
                    "High-Quality WAV + Stems",
                    "Permission to Release on Major Platforms",
                    "Stream Cap: 500,000 Total Streams",
                ],
            },
        ],
        [
            {
                title: "Unlimited",
                price: "$119.99",
                description: "Release Your Music Without Limits",
                features: [
                    "Full Commercial Use License",
                    "Includes WAV + Stems",
                    "Distribute on All Platforms",
                    "Unlimited Streams",
                ],
            },
            {
                title: "Boundless",
                price: "$249.99",
                description: "Break Boundaries with This All-Inclusive License",
                features: [
                    "Sync and Commercial Use Approved",
                    "Full Commercial Rights",
                    "Includes WAV + Stems",
                    "Perform Anywhere (Live Shows, Paid)",
                ],
            },
        ],
    ]

    const nextSlide = () =>
        setIndex((prev) => (prev + 1) % pricingSlides.length)
    const prevSlide = () =>
        setIndex(
            (prev) => (prev - 1 + pricingSlides.length) % pricingSlides.length
        )

    return (
        <div className="relative w-full max-w-2xl mx-auto overflow-hidden">
            <motion.div
                className="flex"
                animate={{ x: `-${index * 100}%` }}
                transition={{ type: "spring", stiffness: 100 }}
            >
                {pricingSlides.map((slide, i) => (
                    <div
                        key={i}
                        className="flex min-w-full justify-between p-4"
                    >
                        {slide.map((item, j) => (
                            <div
                                key={j}
                                className="bg-black text-white p-6 rounded-2xl w-[48%] shadow-lg"
                            >
                                <h3 className="text-xl font-bold">
                                    {item.title}
                                </h3>
                                <p className="text-2xl font-semibold">
                                    {item.price}
                                </p>
                                <p className="text-sm opacity-70">
                                    {item.description}
                                </p>
                                <ul className="mt-3 space-y-2 text-sm">
                                    {item.features.map((feature, k) => (
                                        <li
                                            key={k}
                                            className="flex items-center"
                                        >
                                            ✅ {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                ))}
            </motion.div>

            {/* Navigation Arrows */}
            <div className="absolute top-1/2 left-2 transform -translate-y-1/2">
                <Button
                    onClick={prevSlide}
                    className="p-2 bg-gray-700 rounded-full"
                >
                    ◀
                </Button>
            </div>
            <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                <Button
                    onClick={nextSlide}
                    className="p-2 bg-gray-700 rounded-full"
                >
                    ▶
                </Button>
            </div>

            {/* Pagination Dots */}
            <div className="flex justify-center space-x-2 mt-4">
                {pricingSlides.map((_, i) => (
                    <div
                        key={i}
                        className={`w-3 h-3 rounded-full ${index === i ? "bg-white" : "bg-gray-500"}`}
                    />
                ))}
            </div>
        </div>
    )
}
